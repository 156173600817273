<script lang="ts" setup>
const route = useRoute();

const routes = useRoutes();

const breadcrumbs = computed(() => {
  const slugs = route.fullPath.split("/");

  const paths = slugs.map((_slug, index) => slugs.slice(0, index + 1).join("/"));

  return paths.map((path) => routes.value.find((route) => route.path === path || (path === "" && route.path === "/"))).filter((route): route is NonNullable<typeof route> => !!route);
});
</script>

<template>
  <div class="flex items-center gap-2 text-sm lg:gap-4 print:hidden">
    <template
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
    >
      <NuxtLink
        :to="breadcrumb.path"
        class="text-[inherit] last-of-type:font-normal"
        :class="index == breadcrumbs.length - 1 && 'max-sm:hidden'"
      >
        {{ breadcrumb.title }}
      </NuxtLink>
      <NuxtIcon
        v-if="index !== breadcrumbs.length - 1"
        name="ussif:arrow"
        size="16"
        class="-rotate-90 text-blue"
        :class="index == breadcrumbs.length - 2 && 'max-sm:!hidden'"
      />
    </template>
  </div>
</template>
